import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { Router } from '@angular/router';
import { AuthStorageService } from 'app/main/services/auth-storage.service';
import { LocalstorageService } from 'app/main/services/localstorage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];

  userInfo: any;

  toggleForm = new FormControl();
  languages: string[];
  @Input() currentLanguage: string;
  @Output() myLang = new EventEmitter<string>();

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _authStorage: AuthStorageService,
    private _storage: LocalstorageService,
    private route: Router,
    private _translateService: TranslateService
  ) {
    // Set the defaults
    this.userStatusOptions = [
      {
        title: 'Online',
        icon: 'icon-checkbox-marked-circle',
        color: '#4CAF50',
      },
      {
        title: 'Away',
        icon: 'icon-clock',
        color: '#FFC107',
      },
      {
        title: 'Do not Disturb',
        icon: 'icon-minus-circle',
        color: '#F44336',
      },
      {
        title: 'Invisible',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#BDBDBD',
      },
      {
        title: 'Offline',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#616161',
      },
    ];

    this.navigation = navigation;
    this.userInfo = {};

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.languages = this._translateService.getLangs();
    this.setLanguage();

    // if (this._storage.get('currentLanguage')) {
    //     this._translateService.use(this._storage.get('currentLanguage'));
    // }

    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === 'top';
        this.rightNavbar = settings.layout.navbar.position === 'right';
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });

    if (this._storage.get('userInfo')) {
      const { username, email } = JSON.parse(this._storage.get('userInfo'));

      this.userInfo = {
        username,
        email,
      };
    }
  }

  setLanguage(): void {
    this.currentLanguage = this._storage.get('currentLanguage')
      ? this._storage.get('currentLanguage')
      : this._translateService.currentLang;

    // this.toggleForm.setValue(this.currentLanguage);

    this._translateService.use(this.currentLanguage);
    this._storage.set('currentLanguage', this.currentLanguage);
  }

  changeLanguage(lang: string): void {
    console.log('HERE');
    console.log(lang);

    this._translateService.use(lang);
    this._storage.set('currentLanguage', lang);
    this.myLang.emit(lang);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  logout(): void {
    if (this.userInfo) {
      this.userInfo = undefined;
      this._authStorage.clearAll();
      this.route.navigate(['sys/login']);
    }
  }
}
