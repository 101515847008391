import { Injectable } from '@angular/core';
import { LocalstorageService } from './localstorage.service';

@Injectable({
    providedIn: 'root',
})
export class AuthStorageService {
    tokenName: string = 'tokenAuth';
    constructor(private _storage: LocalstorageService) {}

    setToken(token: string) {
        this._storage.set(this.tokenName, token);
        this.toolbarInfo(this.getInfo());
    }

    getToken() {
        return this._storage.get(this.tokenName);
    }

    getInfo() {
        const token = this._storage.get(this.tokenName);
        const tokenHashed = token.split('.')[1];
        const token_clean = atob(tokenHashed);
        return JSON.parse(token_clean);
    }

    clear() {
        this._storage.remove(this.tokenName);
    }

    clearAll() {
        this._storage.clear();
    }

    toolbarInfo(data) {
        this._storage.set(
            'userInfo',
            JSON.stringify({
                username: data.username,
                email: data.email,
                role: data.role,
            })
        );
    }
}
