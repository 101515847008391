import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LocalstorageService } from 'app/main/services/localstorage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'fuse-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class FuseConfirmDialogComponent implements OnInit {
    public confirmMessage: string;

    /**
     * Constructor
     *
     * @param {MatDialogRef<FuseConfirmDialogComponent>} dialogRef
     */
    constructor(
        public dialogRef: MatDialogRef<FuseConfirmDialogComponent>,
        private _translateService: TranslateService,
        private _storageService: LocalstorageService
    ) {}

    ngOnInit() {
        if (this._storageService.get('currentLanguage')) {
            this._translateService.use(
                this._storageService.get('currentLanguage')
            );
        }
    }
}
