<mat-toolbar class="p-0 mat-elevation-z1">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <button
        mat-icon-button
        class="navbar-toggle-button"
        *ngIf="!hiddenNavbar && !rightNavbar"
        (click)="toggleSidebarOpen('navbar')"
        fxHide.gt-md
      >
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>

      <div
        class="toolbar-separator"
        *ngIf="!hiddenNavbar && !rightNavbar"
        fxHide.gt-md
      ></div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
        <div class="logo ml-16">
          <img class="logo-icon" src="assets/images/logos/fuse.svg" />
        </div>
      </div>
    </div>

    <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
      <button mat-button [matMenuTriggerFor]="languageMenu" class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center">
          <img
            class="mr-0 mr-sm-16"
            width="25"
            style="margin-right: 8px !important"
            src="assets/images/flags/world.png"
          />
          <span class="username mr-12" fxHide fxShow.gt-sm>
            {{ 'LANG_CURRENT' | translate }}
          </span>
          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>
      </button>

      <mat-menu
        #languageMenu="matMenu"
        [formGroup]="toggleForm"
        [overlapTrigger]="false"
      >
        <button
          *ngFor="let lang of languages"
          (click)="changeLanguage(lang)"
          mat-menu-item
        >
          <mat-icon>account_circle</mat-icon>
          <span>{{ lang }}</span>
        </button>
      </mat-menu>

      <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center">
          <img
            class="avatar mr-0 mr-sm-16"
            src="assets/images/avatars/Velazquez.jpg"
          />
          <span class="username mr-12" fxHide fxShow.gt-sm>
            {{ userInfo ? userInfo.username : 'Charlie Adams' }}
          </span>
          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>
      </button>

      <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
        <!-- <button mat-menu-item>
                    <mat-icon>account_circle</mat-icon>
                    <span>Cambiar Contraseña</span>
                </button> -->

        <button mat-menu-item class="" (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>{{ 'TOOLBAR.CLOSE_SESSION' | translate }}</span>
        </button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>
