export const locale = {
  lang: 'en',
  data: {
    NAV: {
      APPLICATIONS: 'Modules',
      DASHBOARDS: 'Dashboards',
      USERS: 'Users',
      ABILITIES: 'Abilities',
      GROUPS: 'Groups',
      COMPANIES: 'Companies',
      HISTORY: 'History',
      CHATBOTS: 'Conversations',
      HOOKS: 'Hooks',
      HOOKS_LIST: 'List',
      CONVERSATIONS: 'Conversations',
      CONVERSATIONS_LIST: 'List',
      SETTINGS: 'Configurations',
      SETTING: 'Settings',
      CALLS: 'Calls',
      CALENDAR: 'Calendar',
      ECOMMERCE: 'E-Commerce',
      ACADEMY: 'Academy',
      MAIL: {
        TITLE: 'Mail',
        BADGE: '25',
      },
      MAIL_NGRX: {
        TITLE: 'Mail Ngrx',
        BADGE: '13',
      },
      CHAT: 'Chat',
      FILE_MANAGER: 'File Manager',
      CONTACTS: 'Contacts',
      ADMINISTRATORS: 'Administrators',
      TODO: 'To-Do',
      SCRUMBOARD: 'Scrumboard',
    },
    WELCOME: 'Welcome to VideoLink!',
    TITLE_FORM_LOGIN: 'LOGIN TO YOUR ACCOUNT',
    EMAIL_FIELD_LOGIN: 'Email',
    EMAIL_ERROR: 'Email is required',
    EMAIL_ERROR_FORMAT: 'Please indicate an email with a valid format',
    PASS_FIELD_LOGIN: 'Password',
    PASS_ERROR: 'Password is required',
    REMEMBER_BUTTON_LOGIN: 'Remember Me',
    FORGOT_BUTTON_LOGIN: 'Forgot Password?',
    BUTTON_LOGIN: 'LOGIN',
    BUTTON_GOOGLE_LOGIN: 'Log in with Google',
    BUTTON_FACEBOOK_LOGIN: 'Log in with Facebook',
    QUESTION_ACCOUNT: "Don't have an account?",
    OR_TEXT: 'OR',
    BUTTON_CREATE_ACCOUNT: 'Create an account',
    TITLE_ERROR_CREDENTIALS: '¡CREDENTIAL ERROR!',
    ERROR_CRENDENTIALS: 'Invalid email or password',
    TITLE_ERROR_UNAUTHORISED: '¡UNAUTHORISED LOGIN!',
    ERROR_UNAUTHORISED: 'You are not authorized to enter the platform',
    TITLE_ERROR_TOKEN: '¡INVALID TOKEN!',
    ERROR_TOKEN: 'Your session has expired',
    TITLE_ERROR_INVALID_USER: 'INVALID USER!',
    MESSAGE_ERROR_INVALID_USER: 'The user entered is not valid',
    TITLE_ERROR_INVALID_INFO: 'INVALID USER/PASSWORD!',
    MESSAGE_ERROR_INVALID_INFO: 'The username or password entered is not valid',
    TOOLBAR: {
      CHANGE_PASS: 'Change Password',
      CLOSE_SESSION: 'Sign off',
    },
    BUTTON_CONFIRM_TITLE: 'Confirm Deletion',
    BUTTON_CONFIRM_DELETE: 'Delete',
    BUTTON_CANCEL_DELETE: 'Cancel',
    LANG_CURRENT: 'en',
    ROLE_SYSTEM_DESCRIPTION: 'System role',
    ROLE_OWNER_DESCRIPTION: 'Superadministrator role',
    ROLE_ADMIN_DESCRIPTION: 'Administrator role',
    ROLE_SUPERVISOR_DESCRIPTION: 'Supervisor role',
    ROLE_AGENT_DESCRIPTION: 'Agent role',
    COMPANIES: {
      COMPANIES: 'Companies',
      COMPANY_FILTER_TEXT: 'Filter companies ...',
      EMPTY_DATA: 'No data matching the filter',
      COL_DEF_1_LIST_COMPANY: 'Name',
      COL_DEF_2_LIST_COMPANY: 'Email',
      COL_DEF_3_LIST_COMPANY: 'Commercial Contact',
      COL_DEF_4_LIST_COMPANY: 'Email C. C.',
      COL_DEF_5_LIST_COMPANY: 'Phone C. C.',
      COL_DEF_6_LIST_COMPANY: 'Technical Contact',
      COL_DEF_7_LIST_COMPANY: 'Email T. C.',
      COL_DEF_8_LIST_COMPANY: 'Phone T. C.',
      COL_DEF_9_LIST_COMPANY: 'Channels',
      COL_DEF_10_LIST_COMPANY: 'Country',
      COL_DEF_11_LIST_COMPANY: 'State',
      COL_DEF_12_LIST_COMPANY: 'City',
      BUTTON_EDIT_COMPANY: 'Edit',
      TITLE_EDIT_COMPANY: 'Edit Company',
      SURE_DELETE_COMPANY: 'Are you sure you want to delete this company?',
      BUTTON_DELETE_COMPANY: 'Delete',
      TITLE_NOTAVAILABLE_EMAIL_COMPANY: 'Company already registered!',
      NOTAVAILABLE_EMAIL_COMPANY: 'Please indicate a different email.',
      COMPANY_SUCCESS_CREATED_MESSAGE:
        'The company has been created successfully!',
      COMPANY_SUCCESS_CREATED_TITLE: 'Company Created!',
      COMPANY_SUCCESS_EDITED_MESSAGE:
        'The company has been edited successfully!',
      COMPANY_SUCCESS_EDITED_TITLE: 'Company Edited!',
      COMPANY_SUCCESS_DELETED_MESSAGE:
        'The company has been deleted successfully!',
      COMPANY_SUCCESS_DELETED_TITLE: 'Company Deleted!',
      FORM1: {
        TITLE: 'Company data',
        CLOSE_DIALOG: 'Close dialog',
        ERROR_NAME_REQUIRED: 'Company name is required',
        ERROR_NAME_MIN: 'Company name must be at least 3 characters',
        ERROR_NAME_MAX: 'Company name must be a maximum of 50 characters',
        ERROR_EMAIL_REQUIRED: 'Email is required',
        ERROR_EMAIL_FORMAT: 'Mail has an invalid format',
        ERROR_COUNTRY_REQUIRED: 'Country is required',
        ERROR_STATE_REQUIRED: 'State is required',
        ERROR_CITY_REQUIRED: 'City is required',
        ERROR_EMAIL_REPEATED: 'Please, indicate a different email',
        ERROR_EMAIL_TITLE_REPEATED: 'Email already registered',
        BUTTON_NEXT: 'Next',
      },
      FORM2: {
        TITLE: 'Contact information',
        ERROR_NAME_COMMERCIAL_REQUIRED: 'Name Commercial Contact is required',
        ERROR_EMAIL_COMMERCIAL_REQUIRED: 'Commercial Contact Email is required',
        ERROR_EMAIL_COMMERCIAL_FORMAT:
          'Commercial Contact Email has an invalid format',
        ERROR_PHONE_COMMERCIAL_REQUIRED:
          'Commercial Contact Telephone is required',
        ERROR_PHONE_COMMERCIAL_NUMBERS:
          'Telephone Business contact must contain only numbers',
        ERROR_NAME_TECHNICAL_REQUIRED: 'Name Technical Contact is required',
        ERROR_EMAIL_TECHNICAL_REQUIRED: 'Email Technical Contact is required',
        ERROR_EMAIL_TECHNICAL_FORMAT:
          'Technical Contact Email has an invalid format',
        ERROR_PHONE_TECHNICAL_REQUIRED:
          'Telephone Technical Contact is required',
        ERROR_PHONE_TECHNICAL_NUMBERS:
          'Telephone Business contact must contain only numbers',
        BUTTON_NEXT: 'Next',
        BUTTON_PREV: 'Previous',
      },
      FORM3: {
        TITLE: 'Thanks!',
        TITLE_INFO_EDIT:
          'Press the "Save Changes" button to confirm and finalize the form',
        TITLE_INFO_ADD:
          'Press the "Add Company" button to confirm and finalize the form',
        BUTTON_SAVE: 'Save Changes',
        BUTTON_ADD: 'Add Company',
        BUTTON_PREV: 'Previous',
      },
    },
    CALLS: {
      CALLS: 'Calls',
      EMPTY_DATA: 'No data matching the filter',
      CALL_FILTER_TEXT: 'Filter calls...',
      COL_DEF_1_LIST_CALL: 'Id',
      COL_DEF_2_LIST_CALL: 'Company',
      COL_DEF_3_LIST_CALL: 'Channel',
      COL_DEF_4_LIST_CALL: 'Client',
      COL_DEF_5_LIST_CALL: 'Start Date',
      COL_DEF_6_LIST_CALL: 'End Date',
      COL_DEF_7_LIST_CALL: 'Agent',
      COL_DEF_8_LIST_CALL: 'Group',
      COL_DEF_9_LIST_CALL: 'Ability',
      COL_DEF_10_LIST_CALL: 'Duration',
      COL_DEF_11_LIST_CALL: 'Client Email',
      COL_DEF_12_LIST_CALL: 'Client Phone',
      COL_DEF_13_LIST_CALL: 'Additional Notes',
      BUTTON_DOWNLOAD_RECORD: 'Download recording',
      BUTTON_DETAIL: 'View detail',
      DETAILS: {
        TITLE: 'Call Detail',
        BUTTON_DOWNLOAD: 'Download recording',
        VIDEO_NOT_AVAILABLE: 'No video available for download.',
      },
    },
    CONVERSATIONS: {
      CONVERSATIONS: 'Conversations',
      CONVERSATION_FILTER_TEXT: 'Filter conversations ...',
      EMPTY_DATA: 'No data matching the filter',
      COL_DEF_1_LIST_CONVERSATION: 'Id',
      COL_DEF_2_LIST_CONVERSATION: 'Session Id',
      COL_DEF_3_LIST_CONVERSATION: 'Conversation Id',
      COL_DEF_4_LIST_CONVERSATION: 'Channel',
      COL_DEF_5_LIST_CONVERSATION: 'Username',
      COL_DEF_6_LIST_CONVERSATION: 'Attended',
      COL_DEF_7_LIST_CONVERSATION: 'Creation date',
      COL_DEF_8_LIST_CONVERSATION: 'Update date',
      HAS_CALL_OPTIONS: {
        YES: 'yes',
        NO: 'no',
        GUEST: 'guest',
      },
      BUTTON_DOWNLOAD_RECORD: 'Download recording',
      BUTTON_DETAIL: 'View detail',
      DETAILS: {
        TITLE: 'Conversation detail',
        TITLE_MESSAGES: 'Messages',
        BUTTON_DOWNLOAD: 'Download recording',
      },
    },
    GROUPS: {
      GROUPS: 'Groups',
      GROUP_FILTER_TEXT: 'Filter groups ...',
      EMPTY_DATA: 'There are no groups created for this user',
      COL_DEF_1_LIST_GROUP: 'Name',
      COL_DEF_2_LIST_GROUP: 'Company',
      COL_DEF_3_LIST_GROUP: 'Country',
      COL_DEF_4_LIST_GROUP: 'State',
      COL_DEF_5_LIST_GROUP: 'City',
      COL_DEF_6_LIST_GROUP: 'Description',
      COL_DEF_7_LIST_GROUP: 'Hook',
      BUTTON_EDIT_GROUP: 'Edit',
      BUTTON_DELETE_GROUP: 'Delete',
      SURE_DELETE_GROUP: 'Are you sure you want to delete this group?',
      NOT_SETTED: 'Not set',
      DEFAULT_NAME: 'Default group',
      FORM: {
        TITLE_EDIT: 'Edit Group',
        TITLE_ADD: 'New Group',
        CLOSE_DIALOG: 'Close Form',
        ERROR_NAME_REQUIRED: 'Group Name is required',
        ERROR_HOOK_REQUIRED: 'At least one app is required',
        ERROR_NAME_MIN: 'Group Name must be at least 3 characters long',
        ERROR_NAME_MAX: 'Group name must have a maximum of 30 characters',
        ERROR_DESCRIPTION_REQUIRED: 'Description is required',
        ERROR_DESCRIPTION_MIN: 'Description must be at least 3 characters long',
        ERROR_DESCRIPTION_MAX:
          'Description must have a maximum of 200 characters',
        ERROR_COMPANY_REQUIRED: 'Company is required',
        ERROR_STATE_REQUIRED: 'State is required',
        ERROR_CITY_REQUIRED: 'City is required',
        BUTTON_SAVE: 'Save Changes',
        BUTTON_DELETE: 'Delete Group',
        BUTTON_ADD: 'Add Group',
      },
    },
    HOOKS: {
      HOOKS: 'Apps',
      HOOK_FILTER_TEXT: 'Search hook',
      EMPTY_DATA: 'No data matching the filter',
      COL_DEF_1_LIST_HOOK: 'Name',
      COL_DEF_2_LIST_HOOK: 'Description',
      COL_DEF_3_LIST_HOOK: 'Domain',
      BUTTON_EDIT_HOOK: 'Edit',
      BUTTON_DELETE_HOOK: 'Delete',
      SURE_DELETE_HOOK: 'Are you sure you want to delete this hook?',
      FORM: {
        TITLE_ADD: 'New Hook',
        TITLE_CLIPBOARD: 'New Seed',
        CLOSE_DIALOG: 'Close Form',
        ERROR_NAME_REQUIRED: 'Hook Name is required',
        ERROR_NAME_MIN: 'Hook Name must be at least 3 characters long',
        ERROR_NAME_MAX: 'Hook name must have a maximum of 30 characters',
        ERROR_NAME_LETTERS: 'Firstname must contain only letters and numbers',
        ERROR_DESCRIPTION_REQUIRED: 'Description is required',
        ERROR_DESCRIPTION_MIN: 'Description must be at least 3 characters long',
        ERROR_DESCRIPTION_MAX:
          'Description must have a maximum of 200 characters',
        ERROR_DOMAIN_REQUIRED: 'Domain is required',
        ERROR_DOMAIN_FORMAT: 'The domain URL format is invalid',
        TOKEN_SUCCESS_TITLE:
          'Your seed has been successfully generated for the application:',
        TOKEN_SUCCESS_COPIED_TITLE: 'SEED COPIED!',
        TOKEN_SUCCESS_COPIED_MESSAGE:
          'Your SEED, has been copied successfully. Keep it in a safe place!',
        TOKEN_WARNING_COPIED_TITLE: 'COPY GENERATED SEED!',
        TOKEN_WARNING_COPIED_MESSAGE:
          'Please be sure to click "Copy to clipboard" to copy and save your generated seed.',
        TOKEN_LABEL: 'Seed to be copied',
        BUTTON_COPY: 'Copy to clipboard',
        BUTTON_DELETE: 'Delete Hook',
        BUTTON_ADD: 'Add Hook',
      },
    },
    USERS: {
      USERS: 'Users',
      USER_FILTER_TEXT: 'Filter users ...',
      EMPTY_DATA: 'No data matching the filter',
      COL_DEF_1_LIST_USER: 'Firstname',
      COL_DEF_2_LIST_USER: 'Lastname',
      COL_DEF_3_LIST_USER: 'Email',
      COL_DEF_4_LIST_USER: 'Status',
      COL_DEF_5_LIST_USER: 'Username',
      COL_DEF_6_LIST_USER: 'Company',
      COL_DEF_7_LIST_USER: 'Group',
      COL_DEF_8_LIST_USER: 'Abilities',
      COL_DEF_9_LIST_USER: 'Role',
      COL_DEF_10_LIST_USER: 'Active',
      COL_DEF_11_LIST_USER: 'Inactive',
      COL_DEF_12_LIST_USER: 'Block',
      COL_DEF_13_LIST_USER: 'Suspended',
      COL_DEF_14_LIST_USER: 'Banned',
      COL_DEF_15_LIST_USER: 'Password',
      COL_DEF_16_LIST_USER: 'Name',
      BUTTON_EDIT_USER: 'Edit',
      BUTTON_DELETE_USER: 'Delete',
      NOT_DISPONIBLE: 'Not availale',
      SURE_DELETE_USER: 'Are you sure you want to delete this user?',
      USER_SUCCESS_CREATED_MESSAGE: 'The user has been created successfully!',
      USER_SUCCESS_CREATED_TITLE: 'User Created!',
      USER_SUCCESS_EDITED_MESSAGE: 'The user has been modified successfully!',
      USER_SUCCESS_EDITED_TITLE: '!User Modified!',
      USER_SUCCESS_DELETED_MESSAGE: 'The user has been deleted successfully!',
      USER_SUCCESS_DELETED_TITLE: '!User Deleted!',
      FORM: {
        TITLE_EDIT: 'Edit User',
        TITLE_ADD: 'New User',
        CLOSE_DIALOG: 'Close Form',
        ERROR_FIRSTNAME_REQUIRED: 'Firstname is required',
        ERROR_FIRSTNAME_MIN: 'Firstname must be at least 3 characters long',
        ERROR_FIRSTNAME_MAX: 'Firstname must have a maximum of 30 characters',
        ERROR_FIRSTNAME_LETTERS: 'Firstname must contain only letters',
        ERROR_LASTNAME_REQUIRED: 'Lastname is required',
        ERROR_LASTNAME_MIN: 'Lastname must be at least 3 characters long',
        ERROR_LASTNAME_MAX: 'Lastname must have a maximum of 30 characters',
        ERROR_LASTNAME_LETTERS: 'Lastname must contain only letters',
        ERROR_EMAIL_REQUIRED: 'Email is required',
        ERROR_EMAIL_FORMAT: 'Email has an invalid format',
        ERROR_EMAIL_REPEATED: 'Please, indicate a different email',
        ERROR_EMAIL_TITLE_REPEATED: 'Email already registered',
        ERROR_USERNAME_REQUIRED: 'Username is required',
        ERROR_USERNAME_MIN: 'Username must be at least 3 characters long',
        ERROR_USERNAME_MAX: 'Username must have a maximum of 30 characters',
        ERROR_USERNAME_LETTERS: 'Username must contain only letters',
        ERROR_USERNAME_REPEATED: 'Please, indicate a different username',
        ERROR_USERNAME_TITLE_REPEATED: 'Username already registered',
        ERROR_PASS_REQUIRED: 'Password is required',
        ERROR_PASS_MIN: 'Password must be at least 6  characters long',
        ERROR_PASS_MAX: 'Password must have a maximum of 15 characters',
        ERROR_COMPANY_REQUIRED: 'Company is required',
        ERROR_GROUP_REQUIRED: 'Group is required',
        ERROR_ABILITY_REQUIRED: 'Ability is required',
        ERROR_ROLE_REQUIRED: 'Role is required',
        BUTTON_SAVE: 'Save Changes',
        BUTTON_DELETE: 'Delete User',
        BUTTON_ADD: 'Add User',
        PASSWORD_VALIDATE_CASE_1: 'At least one capital letter',
        PASSWORD_VALIDATE_CASE_2: 'At least one lowercase letter',
        PASSWORD_VALIDATE_CASE_3: 'At least one special character',
        PASSWORD_VALIDATE_CASE_4: 'At least 3 numeric characters',
        PASSWORD_VALIDATE_CASE_5: 'At least 8 characters in total',
      },
    },
    ABILITIES: {
      ABILITIES: 'Abilities',
      ABILITIES_FILTER_TEXT: 'Filter abilities ...',
      EMPTY_DATA: 'There are no skills created for this user',
      COL_DEF_1_LIST_ABILITIES: 'Name',
      COL_DEF_2_LIST_ABILITIES: 'Company',
      COL_DEF_3_LIST_ABILITIES: 'Description',
      BUTTON_EDIT_ABILITIES: 'Edit',
      BUTTON_DELETE_ABILITIES: 'Delete',
      SURE_DELETE_ABILITY: 'Are you sure you want to delete this ability?',
      FORM: {
        TITLE_EDIT: 'Edit Ability',
        TITLE_ADD: 'New Ability',
        CLOSE_DIALOG: 'Close Form',
        ERROR_NAME_REQUIRED: 'Ability name is required',
        ERROR_NAME_MIN: 'Ability name must be at least 3 characters long',
        ERROR_NAME_MAX: 'Ability name must have a maximum of 30 characters',
        ERROR_COMPANY_REQUIRED: 'Company is required',
        ERROR_DESCRIPTION_REQUIRED: 'Description is required',
        ERROR_DESCRIPTION_MIN: 'Description must be at least 3 characters long',
        ERROR_DESCRIPTION_MAX:
          'Description must have a maximum of 200 characters',
        BUTTON_SAVE: 'Save Changes',
        BUTTON_DELETE: 'Delete Ability',
        BUTTON_ADD: 'Add Ability',
      },
    },
  },
};
