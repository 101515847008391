import { Injectable } from '@angular/core';
import { ApiService } from 'app/main/services/api.service';

@Injectable()
export class UsersService {
  constructor(private api: ApiService) {}

  getAll(): Promise<any> {
    return this.api.get('/user').toPromise();
  }

  create(companyId: string, user: any): Promise<any> {
    return this.api.post(`/c/${companyId}/users`, user).toPromise();
  }

  getRoles(): any | Promise<any> {
    return this.api.get(`/roles`).toPromise();
  }

  edit(companyId: string, user_id: string, user: any): any | Promise<any> {
    // console.log(user_id);
    return this.api.put(`/c/${companyId}/users/${user_id}`, user).toPromise();
  }

  getAllInfo(userId?: string): Promise<any> {
    return userId
      ? this.api.get(`/user/info/${userId}`).toPromise()
      : this.api.get(`/user/info`).toPromise();
  }

  delete(companyId: string, user_id: string): any | Promise<any> {
    return this.api.delete(`/c/${companyId}/users/${user_id}`).toPromise();
  }

  getGroups(company_id: string): any | Promise<any> {
    return this.api.get(`/c/${company_id}/groups`).toPromise();
  }
  getAbilities(company_id: string): any | Promise<any> {
    return this.api.get(`/c/${company_id}/ability`).toPromise();
  }

  usernameAvailable(username: string): boolean | Promise<any> {
    return this.api.get(`/user/available/username/${username}`).toPromise();
  }

  emailAvailable(email: string): boolean | Promise<any> {
    return this.api.get(`/user/available/email/${email}`).toPromise();
  }
}
