export const locale = {
  lang: 'es',
  data: {
    NAV: {
      APPLICATIONS: 'Módulos',
      DASHBOARDS: 'Tableros',
      USERS: 'Usuarios',
      ABILITIES: 'Habilidades',
      GROUPS: 'Grupos',
      COMPANIES: 'Compañías',
      HISTORY: 'Historial',
      CHATBOTS: 'Conversaciones',
      HOOKS: 'Aplicaciones',
      HOOKS_LIST: 'Listado',
      CONVERSATIONS: 'Conversaciones',
      CONVERSATIONS_LIST: 'Listado',
      CALLS: 'Llamadas',
      SETTINGS: 'Configuraciones',
      SETTING: 'Ajustes',
      CALENDAR: 'Calendario',
      ECOMMERCE: 'E-Commerce',
      ACADEMY: 'Academy',
      MAIL: {
        TITLE: 'Mail',
        BADGE: '25',
      },
      MAIL_NGRX: {
        TITLE: 'Mail Ngrx',
        BADGE: '13',
      },
      CHAT: 'Chat',
      FILE_MANAGER: 'File Manager',
      CONTACTS: 'Contacts',
      ADMINISTRATORS: 'Administrators',
      TODO: 'To-Do',
      SCRUMBOARD: 'Scrumboard',
    },
    WELCOME: '!Bienvenido a VideoLink!',
    TITLE_FORM_LOGIN: 'INGRESA A TU CUENTA',
    EMAIL_FIELD_LOGIN: 'Correo',
    EMAIL_ERROR: 'Correo es requerido',
    EMAIL_ERROR_FORMAT: 'Por favor, indique un correo con formato válido',
    PASS_FIELD_LOGIN: 'Contraseña',
    PASS_ERROR: 'Contraseña es requerida',
    REMEMBER_BUTTON_LOGIN: 'Recordar',
    FORGOT_BUTTON_LOGIN: 'Olvidó su contraseña?',
    BUTTON_LOGIN: 'INGRESAR',
    BUTTON_GOOGLE_LOGIN: 'Ingresar con Google',
    BUTTON_FACEBOOK_LOGIN: 'Ingresar con Facebook',
    QUESTION_ACCOUNT: '¿No tienes una cuenta?',
    OR_TEXT: 'ó',
    BUTTON_CREATE_ACCOUNT: 'Crea una cuenta',
    TITLE_ERROR_CREDENTIALS: '¡ERROR DE CREDENCIALES!',
    ERROR_CRENDENTIALS: 'Email o contraseña inválido(a)',
    TITLE_ERROR_UNAUTHORISED: '¡INICIO DE SESIÓN NO AUTORIZADO!',
    ERROR_UNAUTHORISED: 'No está autorizado para ingresar a la plataforma',
    TITLE_ERROR_TOKEN: '¡TOKEN INVÁLIDO!',
    ERROR_TOKEN: 'Su sesión ha expirado',
    TITLE_ERROR_INVALID_USER: '!USUARIO INVÁLIDO!',
    MESSAGE_ERROR_INVALID_USER: 'El usuario ingresado no es válido',
    TITLE_ERROR_INVALID_INFO: '!USUARIO/CONTRASEÑA INVÁLIDOS!',
    MESSAGE_ERROR_INVALID_INFO:
      'El usuario o contraseña ingresados no son válidos',
    TOOLBAR: {
      CHANGE_PASS: 'Cambiar Contraseña',
      CLOSE_SESSION: 'Cerrar Sesión',
    },
    BUTTON_CONFIRM_TITLE: 'Confirmar Eliminación',
    BUTTON_CONFIRM_DELETE: 'Eliminar',
    BUTTON_CANCEL_DELETE: 'Cancelar',
    LANG_CURRENT: 'es',
    ROLE_SYSTEM_DESCRIPTION: 'Rol de sistema',
    ROLE_OWNER_DESCRIPTION: 'Rol de superadministrador',
    ROLE_ADMIN_DESCRIPTION: 'Rol de administrador',
    ROLE_SUPERVISOR_DESCRIPTION: 'Rol de supervisor',
    ROLE_AGENT_DESCRIPTION: 'Rol de agente',
    COMPANIES: {
      COMPANIES: 'Companías',
      COMPANY_FILTER_TEXT: 'Buscar compañías ...',
      EMPTY_DATA: 'No hay coincidencias de búsqueda',
      COL_DEF_1_LIST_COMPANY: 'Nombre',
      COL_DEF_2_LIST_COMPANY: 'Correo',
      COL_DEF_3_LIST_COMPANY: 'Contacto Comercial',
      COL_DEF_4_LIST_COMPANY: 'Correo C. C.',
      COL_DEF_5_LIST_COMPANY: 'Teléfono C. C.',
      COL_DEF_6_LIST_COMPANY: 'Contacto Técnico',
      COL_DEF_7_LIST_COMPANY: 'Correo C. T.',
      COL_DEF_8_LIST_COMPANY: 'Teléfono C. T.',
      COL_DEF_9_LIST_COMPANY: 'Canales',
      COL_DEF_10_LIST_COMPANY: 'País',
      COL_DEF_11_LIST_COMPANY: 'Estado',
      COL_DEF_12_LIST_COMPANY: 'Ciudad',
      BUTTON_EDIT_COMPANY: 'Editar',
      TITLE_EDIT_COMPANY: 'Editar Compañía',
      SURE_DELETE_COMPANY: '¿Está seguro de que desea eliminar ésta compañía?',
      BUTTON_DELETE_COMPANY: 'Eliminar',
      TITLE_NOTAVAILABLE_EMAIL_COMPANY: '¡Compañía ya registrada!',
      NOTAVAILABLE_EMAIL_COMPANY: 'Por favor, indique un email distinto.',
      COMPANY_SUCCESS_CREATED_MESSAGE:
        'La compañía ha sido creada exitosamente!',
      COMPANY_SUCCESS_CREATED_TITLE: '¡Compañía Creada!',
      COMPANY_SUCCESS_EDITED_MESSAGE:
        'La compañía ha sido editada exitosamente!',
      COMPANY_SUCCESS_EDITED_TITLE: '¡Compañía Editada!',
      COMPANY_SUCCESS_DELETED_MESSAGE:
        'La compañía ha sido eliminada exitosamente!',
      COMPANY_SUCCESS_DELETED_TITLE: '¡Compañía Eliminada!',
      FORM1: {
        TITLE: 'Datos de la Empresa',
        CLOSE_DIALOG: 'Cerrar Formulario',
        ERROR_NAME_REQUIRED: 'Nombre de compañía es requerido',
        ERROR_NAME_MIN: 'Nombre de compañía debe tener al menos 3 caracteres',
        ERROR_NAME_MAX:
          'Nombre de compañía debe tener un máximo de 50 caracteres',
        ERROR_EMAIL_REQUIRED: 'Correo es requerido',
        ERROR_EMAIL_FORMAT: 'Correo tiene un formato inválido',
        ERROR_COUNTRY_REQUIRED: 'País es requerido',
        ERROR_STATE_REQUIRED: 'Estado es requerido',
        ERROR_CITY_REQUIRED: 'Ciudad es requerida',
        ERROR_EMAIL_REPEATED: 'Por favor, indique un email diferente',
        ERROR_EMAIL_TITLE_REPEATED: 'Email ya registrado',
        BUTTON_NEXT: 'Siguiente',
      },
      FORM2: {
        TITLE: 'Datos de Contacto',
        ERROR_NAME_COMERCIAL_REQUIRED: 'Nombre Contacto Comercial es requerido',
        ERROR_EMAIL_COMERCIAL_REQUIRED:
          'Correo Contacto Comercial es requerido',
        ERROR_EMAIL_COMERCIAL_FORMAT:
          'Correo Contacto Comercial tiene un formato inválido',
        ERROR_PHONE_COMERCIAL_REQUIRED:
          'Teléfono Contacto Comercial es requerido',
        ERROR_PHONE_COMERCIAL_NUMBERS:
          'Teléfono Contacto comercial debe contener sólo números',
        ERROR_NAME_TECNICAL_REQUIRED: 'Nombre Contacto Técnico es requerido',
        ERROR_EMAIL_TECNICAL_REQUIRED: 'Correo Contacto Técnico es requerido',
        ERROR_EMAIL_TECNICAL_FORMAT:
          'Correo Contacto Técnico tiene un formato inválido',
        ERROR_PHONE_TECNICAL_REQUIRED: 'Teléfono Contacto Técnico es requerido',
        ERROR_PHONE_TECNICAL_NUMBERS:
          'Teléfono Contacto comercial debe contener sólo números',
        BUTTON_NEXT: 'Siguiente',
        BUTTON_PREV: 'Anterior',
      },
      FORM3: {
        TITLE: '¡Gracias!',
        TITLE_INFO_EDIT:
          'Presiona el boton "Guardar Cambios" para confirmar y finalizar el formulario',
        TITLE_INFO_ADD:
          'Presiona el boton "Agregar Compañía" para confirmar y finalizar el formulario',
        BUTTON_SAVE: 'Guardar Cambios',
        BUTTON_ADD: 'Agregar Compañía',
        BUTTON_PREV: 'Anterior',
      },
    },
    CALLS: {
      CALLS: 'Llamadas',
      CALL_FILTER_TEXT: 'Buscar llamadas...',
      EMPTY_DATA: 'No hay coincidencias de búsqueda',
      COL_DEF_1_LIST_CALL: 'Id',
      COL_DEF_2_LIST_CALL: 'Compañía',
      COL_DEF_3_LIST_CALL: 'Canal',
      COL_DEF_4_LIST_CALL: 'Cliente',
      COL_DEF_5_LIST_CALL: 'Fecha Inicio',
      COL_DEF_6_LIST_CALL: 'Fecha Fin',
      COL_DEF_7_LIST_CALL: 'Agente',
      COL_DEF_8_LIST_CALL: 'Grupo',
      COL_DEF_9_LIST_CALL: 'Habilidad',
      COL_DEF_10_LIST_CALL: 'Duración',
      COL_DEF_11_LIST_CALL: 'Correo Cliente',
      COL_DEF_12_LIST_CALL: 'Teléfono Cliente',
      COL_DEF_13_LIST_CALL: 'Notas Adicionales',
      BUTTON_DOWNLOAD_RECORD: 'Descargar grabación',
      BUTTON_DETAIL: 'Ver detalle',
      DETAILS: {
        TITLE: 'Detalle de Llamada',
        BUTTON_DOWNLOAD: 'Descargar grabación',
        VIDEO_NOT_AVAILABLE: 'No hay video disponible para la descarga.',
      },
    },
    CONVERSATIONS: {
      CONVERSATIONS: 'Conversaciones',
      CONVERSATION_FILTER_TEXT: 'Buscar conversaciones...',
      EMPTY_DATA: 'No hay coincidencias de búsqueda',
      COL_DEF_1_LIST_CONVERSATION: 'Id',
      COL_DEF_2_LIST_CONVERSATION: 'Id Sesión',
      COL_DEF_3_LIST_CONVERSATION: 'Id Conversación',
      COL_DEF_4_LIST_CONVERSATION: 'Canal',
      COL_DEF_5_LIST_CONVERSATION: 'Nombre de Usuario',
      COL_DEF_6_LIST_CONVERSATION: 'Atendido',
      COL_DEF_7_LIST_CONVERSATION: 'Fecha Creación',
      COL_DEF_8_LIST_CONVERSATION: 'Fecha Actualización',
      HAS_CALL_OPTIONS: {
        YES: 'si',
        NO: 'no',
        GUEST: 'invitado',
      },
      BUTTON_DOWNLOAD_RECORD: 'Descargar grabación',
      BUTTON_DETAIL: 'Ver detalle',
      DETAILS: {
        TITLE: 'Detalle de la Conversación',
        TITLE_MESSAGES: 'Mensajes',
        BUTTON_DOWNLOAD: 'Descargar grabación',
      },
    },
    GROUPS: {
      GROUPS: 'Grupos',
      GROUP_FILTER_TEXT: 'Buscar grupos ...',
      EMPTY_DATA: 'No hay grupos creados para este usuario',
      COL_DEF_1_LIST_GROUP: 'Nombre',
      COL_DEF_2_LIST_GROUP: 'Compañía',
      COL_DEF_3_LIST_GROUP: 'País',
      COL_DEF_4_LIST_GROUP: 'Estado',
      COL_DEF_5_LIST_GROUP: 'Ciudad',
      COL_DEF_6_LIST_GROUP: 'Descripción',
      COL_DEF_7_LIST_GROUP: 'Aplicación',
      BUTTON_EDIT_GROUP: 'Editar',
      BUTTON_DELETE_GROUP: 'Eliminar',
      SURE_DELETE_GROUP: '¿Está seguro de que desea eliminar éste grupo?',
      NOT_SETTED: 'No seteado',
      DEFAULT_NAME: 'Grupo por defecto',
      FORM: {
        TITLE_EDIT: 'Editar Grupo',
        TITLE_ADD: 'Nuevo Grupo',
        CLOSE_DIALOG: 'Close Form',
        ERROR_NAME_REQUIRED: 'Nombre de Grupo es requerido',
        ERROR_HOOK_REQUIRED: 'Al menos una aplicación es requerida',
        ERROR_NAME_MIN: 'Nombre de grupo debe tener al menos 3 caracteres',
        ERROR_NAME_MAX: 'Nombre de grupo debe tener un máximo de 30 caracteres',
        ERROR_DESCRIPTION_REQUIRED: 'Descripción es requerido',
        ERROR_DESCRIPTION_MIN: 'Descripción debe tener al menos 3 caracteres',
        ERROR_DESCRIPTION_MAX:
          'Descripción debe tener un máximo de 200 caracteres',
        ERROR_COMPANY_REQUIRED: 'Compañía es requerida',
        ERROR_STATE_REQUIRED: 'Estado es requerido',
        ERROR_CITY_REQUIRED: 'Ciudad es requerido',
        BUTTON_SAVE: 'Guardar Cambios',
        BUTTON_DELETE: 'Eliminar Grupo',
        BUTTON_ADD: 'Agregar Grupo',
      },
    },
    HOOKS: {
      HOOKS: 'Aplicaciones',
      HOOK_FILTER_TEXT: 'Buscar aplicación',
      EMPTY_DATA: 'No hay coincidencias de búsqueda',
      COL_DEF_1_LIST_HOOK: 'Nombre',
      COL_DEF_2_LIST_HOOK: 'Descripción',
      COL_DEF_3_LIST_HOOK: 'Dominio',
      BUTTON_DELETE_HOOK: 'Eliminar',
      SURE_DELETE_HOOK: '¿Está seguro de que desea eliminar ésta aplicación?',
      FORM: {
        TITLE_ADD: 'Nueva Aplicación',
        TITLE_CLIPBOARD: 'Nueva Semilla',
        CLOSE_DIALOG: 'Close Form',
        ERROR_NAME_REQUIRED: 'Nombre de Aplicación es requerido',
        ERROR_NAME_MIN: 'Nombre de aplicación debe tener al menos 3 caracteres',
        ERROR_NAME_MAX:
          'Nombre de aplicación debe tener un máximo de 30 caracteres',
        ERROR_FIRSTNAME_LETTERS: 'Nombre debe contener solo letras y números',
        ERROR_DESCRIPTION_REQUIRED: 'Descripción es requerido',
        ERROR_DESCRIPTION_MIN: 'Descripción debe tener al menos 3 caracteres',
        ERROR_DESCRIPTION_MAX:
          'Descripción debe tener un máximo de 200 caracteres',
        ERROR_DOMAIN_REQUIRED: 'Dominio es requerido',
        ERROR_DOMAIN_FORMAT: 'El formato de dominio URL es inválido',
        TOKEN_SUCCESS_TITLE:
          'Se ha generado exitosamente su semilla para la aplicación:',
        TOKEN_SUCCESS_COPIED_TITLE: '¡SEMILLA COPIADA!',
        TOKEN_SUCCESS_COPIED_MESSAGE:
          'Su SEMILLA se ha copiado correctamente. ¡Guárdela en un lugar seguro!',
        TOKEN_WARNING_COPIED_TITLE: '¡COPIAR SEMILLA GENERADA!',
        TOKEN_WARNING_COPIED_MESSAGE:
          'Por favor, asegúrese de hacer click en "Copiar al portapapeles" para copiar y resguardar su semilla generada.',
        TOKEN_LABEL: 'Semilla a copiar',
        BUTTON_COPY: 'Copiar al portapapeles',
        BUTTON_DELETE: 'Eliminar Aplicación',
        BUTTON_ADD: 'Agregar Aplicación',
      },
    },
    USERS: {
      USERS: 'Usuarios',
      USER_FILTER_TEXT: 'Buscar usuarios ...',
      EMPTY_DATA: 'No hay coincidencias de búsqueda',
      COL_DEF_1_LIST_USER: 'Nombre',
      COL_DEF_2_LIST_USER: 'Apellido',
      COL_DEF_3_LIST_USER: 'Correo',
      COL_DEF_4_LIST_USER: 'Estatus',
      COL_DEF_5_LIST_USER: 'Nombre de Usuario',
      COL_DEF_6_LIST_USER: 'Compañía',
      COL_DEF_7_LIST_USER: 'Grupo',
      COL_DEF_8_LIST_USER: 'Habilidades',
      COL_DEF_9_LIST_USER: 'Rol',
      COL_DEF_10_LIST_USER: 'Activo',
      COL_DEF_11_LIST_USER: 'Inactivo',
      COL_DEF_12_LIST_USER: 'Bloqueado',
      COL_DEF_13_LIST_USER: 'Suspendido',
      COL_DEF_14_LIST_USER: 'Prohibido',
      COL_DEF_15_LIST_USER: 'Contraseña',
      COL_DEF_16_LIST_USER: 'Nombre',
      BUTTON_EDIT_USER: 'Editar',
      BUTTON_DELETE_USER: 'Eliminar',
      NOT_DISPONIBLE: 'No disponible',
      SURE_DELETE_USER: '¿Está seguro de que desea eliminar éste usuario?',
      USER_SUCCESS_CREATED_MESSAGE: 'El usuario ha sido creado exitosamente!',
      USER_SUCCESS_CREATED_TITLE: '!Usuario Creado!',
      USER_SUCCESS_EDITED_MESSAGE:
        'El usuario ha sido modificado exitosamente!',
      USER_SUCCESS_EDITED_TITLE: '!Usuario Modificado!',
      USER_SUCCESS_DELETED_MESSAGE:
        'El usuario ha sido eliminado exitosamente!',
      USER_SUCCESS_DELETED_TITLE: '!Usuario Eliminado!',
      FORM: {
        TITLE_EDIT: 'Editar Usuario',
        TITLE_ADD: 'Nuevo Usuario',
        CLOSE_DIALOG: 'Cerrar Formulario',
        ERROR_FIRSTNAME_REQUIRED: 'Nombre es requerido',
        ERROR_FIRSTNAME_MIN: 'Nombre debe tener al menos 3 caracteres',
        ERROR_FIRSTNAME_MAX: 'Nombre debe tener un máximo de 30 caracteres',
        ERROR_FIRSTNAME_LETTERS: 'Nombre debe contener solo letras',
        ERROR_LASTNAME_REQUIRED: 'Apellido es requerido',
        ERROR_LASTNAME_MIN: 'Apellido debe tener al menos 3 caracteres',
        ERROR_LASTNAME_MAX: 'Apellido debe tener un máximo de 30 caracteres',
        ERROR_LASTNAME_LETTERS: 'Apellido debe contener solo letras',
        ERROR_EMAIL_REQUIRED: 'Correo es requerido',
        ERROR_EMAIL_FORMAT: 'Correo tiene un formato inválido',
        ERROR_EMAIL_REPEATED: 'Por favor, indique un email diferente',
        ERROR_EMAIL_TITLE_REPEATED: 'Email ya registrado',
        ERROR_USERNAME_REQUIRED: 'Nombre de usuario es requerido',
        ERROR_USERNAME_MIN:
          'Nombre de usuario debe tener al menos 3 caracteres',
        ERROR_USERNAME_MAX:
          'Nombre de usuario debe tener un máximo de 30 caracteres',
        ERROR_USERNAME_LETTERS: 'Nombre de usuario debe contener solo letras',
        ERROR_USERNAME_REPEATED:
          'Por favor, indique un nombre de usuario diferente',
        ERROR_USERNAME_TITLE_REPEATED: 'Nombre de usuario ya registrado',
        ERROR_PASS_REQUIRED: 'Contraseña es requerido',
        ERROR_PASS_MIN: 'Contraseña debe tener al menos 6 caracteres',
        ERROR_PASS_MAX: 'Contraseña debe tener un máximo de 15 caracteres',
        ERROR_COMPANY_REQUIRED: 'Compañía es requerida',
        ERROR_GROUP_REQUIRED: 'Grupo es requerido',
        ERROR_ABILITY_REQUIRED: 'Habilidad es requerida',
        ERROR_ROLE_REQUIRED: 'Rol es requerido',
        BUTTON_SAVE: 'Guardar Cambios',
        BUTTON_DELETE: 'Eliminar Usuario',
        BUTTON_ADD: 'Agregar Usuario',
        PASSWORD_VALIDATE_CASE_1: 'Al menos una letra mayúscula',
        PASSWORD_VALIDATE_CASE_2: 'Al menos una letra minúscula',
        PASSWORD_VALIDATE_CASE_3: 'Al menos un carácter especial',
        PASSWORD_VALIDATE_CASE_4: 'Al menos 3 caracteres numéricos',
        PASSWORD_VALIDATE_CASE_5: 'Al menos 8 caracteres en total',
      },
    },
    ABILITIES: {
      ABILITIES: 'Habilidades',
      ABILITIES_FILTER_TEXT: 'Buscar habilidades ...',
      EMPTY_DATA: 'No hay habilidades creadas para este usuario',
      COL_DEF_1_LIST_ABILITIES: 'Nombre',
      COL_DEF_2_LIST_ABILITIES: 'Compañía',
      COL_DEF_3_LIST_ABILITIES: 'Descripción',
      BUTTON_EDIT_ABILITIES: 'Editar',
      BUTTON_DELETE_ABILITIES: 'Eliminar',
      SURE_DELETE_ABILITY: '¿Está seguro de que desea eliminar ésta habilidad?',
      FORM: {
        TITLE_EDIT: 'Editar Habilidad',
        TITLE_ADD: 'Nueva Habilidad',
        CLOSE_DIALOG: 'Cerrar Formulario',
        ERROR_NAME_REQUIRED: 'Nombre de Habilidad es requerido',
        ERROR_NAME_MIN: 'Nombre de Habilidad debe tener al menos 3 caracteres',
        ERROR_NAME_MAX:
          'Nombre de Habilidad debe tener un máximo de 30 caracteres',
        ERROR_COMPANY_REQUIRED: 'Compañia es requerida',
        ERROR_DESCRIPTION_REQUIRED: 'Descripción es requerido',
        ERROR_DESCRIPTION_MIN: 'Descripción debe tener al menos 3 caracteres',
        ERROR_DESCRIPTION_MAX:
          'Descripción debe tener un máximo de 200 caracteres',
        BUTTON_SAVE: 'Guardar Cambios',
        BUTTON_DELETE: 'Eliminar Habilidad',
        BUTTON_ADD: 'Agregar Habilidad',
      },
    },
  },
};
