import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthStorageService } from '../services/auth-storage.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AuthInterceptorService {
    constructor(
        private _authService: AuthStorageService,
        private router: Router
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // Obtengo el token actual de la sesion...
        const token = this._authService.getToken();
        let request = req;

        const headers: any = {
            'Content-Type': 'application/json',
        };

        // Validacion de existencia del token para agregar la cabecera...
        if (token) {
            // Modificación del request añadiendo la cabecera de autorizacion de videolink...
            headers.Authorization = `videolink ${token}`;
        }

        request = req.clone({
            setHeaders: headers,
        });

        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                // Redirección al login en caso de status 401...
                if (err.status === 401) {
                    this.router.navigate(['videolink/login']);
                }

                return throwError(err);
            })
        );
    }
}
