<h1 matDialogTitle>{{ 'BUTTON_CONFIRM_TITLE' | translate }}</h1>
<div mat-dialog-content>{{ confirmMessage }}</div>
<div mat-dialog-actions class="pt-24" fxLayout="row" fxLayoutAlign="end center">
  <button mat-button (click)="dialogRef.close(false)">
    {{ 'BUTTON_CANCEL_DELETE' | translate }}
  </button>
  <button
    mat-raised-button
    class="mat-accent mr-16"
    (click)="dialogRef.close(true)"
  >
    {{ 'BUTTON_CONFIRM_DELETE' | translate }}
  </button>
</div>
