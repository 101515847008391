import { Injectable } from '@angular/core'
import { ApiService } from 'app/main/services/api.service'

@Injectable({
  providedIn: 'root',
})
export class PreferencesService {
  constructor(private api: ApiService) {}

  getPreferences(company_id: string): any | Promise<any> {
    return this.api.get(`/c/${company_id}/pref`).toPromise()
  }

  enableDisablePreferences(company_id: string, module: any) {
    return this.api.post(`/c/${company_id}/pref/module`, module).toPromise()
  }

  updatePreferences(company_id: string, module: any) {
    return this.api.put(`/c/${company_id}/pref/edit`, module).toPromise()
  }

  getCustomFields(company_id: string): any | Promise<any> {
    return this.api.get(`/c/${company_id}/pref/fields`).toPromise()
  }

  getMemberships(
    company_id: string,
    membershipId?: string
  ): any | Promise<any> {
    return membershipId
      ? this.api.get(`/c/${company_id}/memberships/${membershipId}`).toPromise()
      : this.api.get(`/c/${company_id}/memberships`).toPromise()
  }
}
