import { InMemoryDbService } from 'angular-in-memory-web-api';

import { CompaniesFakeDb } from 'app/fake-db/company';
import { GroupsFakeDb } from 'app/fake-db/group';
import { AbilitiesFakeDb } from 'app/fake-db/ability';
import { UsersFakeDb } from 'app/fake-db/user';
import { ProjectDashboardDb } from 'app/fake-db/dashboard-project';
import { AnalyticsDashboardDb } from 'app/fake-db/dashboard-analytics';
import { CalendarFakeDb } from 'app/fake-db/calendar';
import { ContactsFakeDb } from 'app/fake-db/contacts';

import { ProfileFakeDb } from 'app/fake-db/profile';
import { SearchFakeDb } from 'app/fake-db/search';
import { IconsFakeDb } from 'app/fake-db/icons';
import { QuickPanelFakeDb } from 'app/fake-db/quick-panel';

export class FakeDbService implements InMemoryDbService {
    createDb(): any {
        return {
            // Companies
            'companies-companies': CompaniesFakeDb.companies,

            // Groups
            'groups-groups': GroupsFakeDb.groups,
            'groups-user': GroupsFakeDb.user,
            // Abilities
            'abilities-abilities': AbilitiesFakeDb.abilities,
            'abilities-user': AbilitiesFakeDb.user,
            // Users
            'users-users': UsersFakeDb.users,

            // Dashboards
            'project-dashboard-projects': ProjectDashboardDb.projects,
            'project-dashboard-widgets': ProjectDashboardDb.widgets,
            'analytics-dashboard-widgets': AnalyticsDashboardDb.widgets,

            // Calendar
            calendar: CalendarFakeDb.data,

            // Contacts
            'contacts-contacts': ContactsFakeDb.contacts,
            'contacts-user': ContactsFakeDb.user,

            // Profile
            'profile-timeline': ProfileFakeDb.timeline,
            'profile-photos-videos': ProfileFakeDb.photosVideos,
            'profile-about': ProfileFakeDb.about,

            // Search
            search: SearchFakeDb.search,

            // Icons
            icons: IconsFakeDb.icons,

            // Quick Panel
            'quick-panel-notes': QuickPanelFakeDb.notes,
            'quick-panel-events': QuickPanelFakeDb.events,
        };
    }
}
