// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  // server: 'https://api.qa.videolink.app',
  // server2: 'https://frb0l9zczj.execute-api.us-east-1.amazonaws.com/dev',
  // serverRemote: 'https://api.qa.videolink.app',
  // files: 'https://cdn.qa.videolink.app',
  logo: 'assets/images/logos/videolink_logo.png',
  frontendURL: 'http://localhost:4800/sys/',

  serverDev: 'http://admin.dev.videolink.app/admin/',
  serverRemote: 'https://api.dev.videolink.app',
  wsServer: 'https://ws.dev.videolink.app',
  storage: 'https://cdn.dev.videolink.app',
  files: 'https://cdn.dev.videolink.app',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
