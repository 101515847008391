import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { UsersService } from 'app/main/apps/users/users.service';
import { AuthStorageService } from 'app/main/services/auth-storage.service';
import { PreferencesService } from 'app/main/services/preferences.service';
import { Router } from '@angular/router';
import { sizeNavigation } from 'app/navigation/navigation';

@Component({
  selector: 'fuse-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FuseNavigationComponent implements OnInit {
  @Input()
  layout = 'vertical';

  @Input()
  navigation: any;

  userInfo: any;
  myInfo: any;
  appointmentsStatus: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {FuseNavigationService} _fuseNavigationService
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _fuseNavigationService: FuseNavigationService,
    private _usersService: UsersService,
    private _authService: AuthStorageService,
    private _preferencesService: PreferencesService,
    private _router: Router
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    if (this._authService.getToken()) {
      this.userInfo = this._authService.getInfo();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  async ngOnInit(): Promise<void> {
    if (this.userInfo) {
      this.myInfo = await this._usersService.getAllInfo(this.userInfo.user);

      // this.navigation =
      //   this.navigation || this._fuseNavigationService.getCurrentNavigation();

      // Subscribe to the current navigation changes
      this._fuseNavigationService.onNavigationChanged
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
          // Load the navigation
          this.navigation = this._fuseNavigationService.getCurrentNavigation();
          this.checkItemsToDisplay();

          // Mark for check
          this._changeDetectorRef.markForCheck();
        });

      // Subscribe to navigation item
      merge(
        this._fuseNavigationService.onNavigationItemAdded,
        this._fuseNavigationService.onNavigationItemUpdated,
        this._fuseNavigationService.onNavigationItemRemoved
      )
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
          // Mark for check
          this._changeDetectorRef.markForCheck();
        });
    }
  }

  checkItemsToDisplay(): void {
    const currentUserRole = this.myInfo[0].role.level;
    const { size } = sizeNavigation;

    // const activities = {
    //   id: 'activities',
    //   title: 'Historial',
    //   translate: 'NAV.ACTIVITIES',
    //   type: 'item',
    //   icon: 'event',
    //   url: '/apps/activities',
    // }
    // const configurations = {
    //   id: 'configurations',
    //   title: 'Configurations',
    //   translate: 'NAV.SETTING_GENERAL',
    //   type: 'item',
    //   icon: 'settings',
    //   url: '/apps/company-settings',
    // }

    // if (this.navigation[0].children.length < size - 1) {
    //   this.navigation[0].children.push({
    //     id: '',
    //     title: '',
    //     translate: '',
    //     type: 'collapsable',
    //     icon: '',
    //     children: [],
    //   })
    // }

    // this.navigation[0].children.push(configurations)

    // if (currentUserRole >= 1000) {
    //   if (this.navigation[0].children[4].id !== 'activities') {
    //     this.navigation[0].children.splice(4, 0, activities)
    //   }

    //   this.navigation[0].children[size].id = 'appointments'
    //   this.navigation[0].children[size].title = 'Citas'
    //   this.navigation[0].children[size].translate = 'NAV.APPOINTMENTS'
    //   this.navigation[0].children[size].icon = 'event'

    //   this.navigation[0].children[size].children = [
    //     {
    //       id: 'listCalendar',
    //       title: 'Listado',
    //       translate: 'NAV.HOOKS_LIST',
    //       type: 'item',
    //       icon: 'view_list',
    //       url: '/apps/list/appointments',
    //     },
    //     {
    //       id: 'calendar',
    //       title: 'Calendario',
    //       translate: 'NAV.SCHEDULED',
    //       type: 'item',
    //       icon: 'event_available',
    //       url: '/apps/calendar/appointments',
    //     },
    //     {
    //       id: 'settings',
    //       title: 'Ajustes',
    //       translate: 'NAV.SETTING',
    //       type: 'item',
    //       icon: 'settings',
    //       url: '/apps/settings/appointments',
    //     },
    //   ]
    // } else {
    //   if (this.navigation[0].children[4].id === 'activities') {
    //     this.navigation[0].children.splice(4, 1)
    //   }

    //   if (this.navigation[0].children[size].id === 'configurations') {
    //     this.navigation[0].children.splice(size, 1)
    //   }

    //   const mySize =
    //     this.navigation[0].children.length === size - 1
    //       ? this.navigation[0].children.length
    //       : size

    //   if (
    //     this.appointmentsStatus.hasOwnProperty('appointments') &&
    //     this.appointmentsStatus.appointments
    //   ) {
    //     this.navigation[0].children[mySize - 1].id = 'appointments'
    //     this.navigation[0].children[mySize - 1].title = 'Citas'
    //     this.navigation[0].children[mySize - 1].translate = 'NAV.APPOINTMENTS'
    //     this.navigation[0].children[mySize - 1].icon = 'event'

    //     this.navigation[0].children[mySize - 1].children = [
    //       {
    //         id: 'listCalendar',
    //         title: 'Listado',
    //         translate: 'NAV.HOOKS_LIST',
    //         type: 'item',
    //         icon: 'view_list',
    //         url: '/apps/list/appointments',
    //       },
    //       {
    //         id: 'calendar',
    //         title: 'Calendario',
    //         translate: 'NAV.SCHEDULED',
    //         type: 'item',
    //         icon: 'event_available',
    //         url: '/apps/calendar/appointments',
    //       },
    //     ]
    //   } else {
    //     // this.navigation[0].children.pop()
    //     this.navigation[0].children.splice(mySize - 1, 1)
    //     this._router.navigate(['/apps/conversations'])
    //   }
    // }
  }
}
